.anim-visible {
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
}

.anim-visible-appear {
  transform: translateY(0);
  opacity: 1;
}

.anim-static {
  transform: translateY(0);
  opacity: 1;
}