@import "_variables";

@mixin make-container($padding-x: $container-padding-x) {
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

@mixin media-up($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media screen and (min-width: $query) {
      @content;
    }
  } @else {
  @error 'Не указано значение для `#{$breakpoint}`. '
       + 'Пожалуйста, убедитесь, что точка останова объявлена в карте `$breakpoints`.';
  }
}

@mixin flex($display: flex, $direction: row, $justify: null, $align: null, $wrap: null) {
  display: $display;
  flex-direction: $direction;
  @if ($justify) {
    justify-content: $justify;
  }
  @if ($align) {
    align-items: $align;
  }
  @if ($wrap) {
    flex-wrap: $wrap;
  }
}