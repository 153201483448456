@import "../../scss/variables";

.App-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 30px 20px;
  @media screen and (max-width: 768.98px) {
    padding: 20px 0;
  }
  @media screen and (max-width: 575.98px)  {
    ul {
      padding-left: 0;
      padding-bottom: 10px;
    }
  }
  ul li {
    list-style-type: none;
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 40px;
    }
    a {
      display: inline-block;
      text-decoration: none;
      color: $color-primary;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
    }
  }
}

.logo svg {
  width: 100%;
  height: 60px;
}