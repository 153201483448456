@import "./scss/_variables";
@import "./scss/_mixins";
@import "./fonts/style.css";

:root {
  --animation-time: 6s;
}

.App {
  min-height: 100vh;
  & * {
    box-sizing: border-box;
  }
}

h1 {
  font-family: 'Courgette';
}

h2,h3,h4,h5,h6,label {
  font-family: 'Overlock-Bold' !important;
}

.container, .container-fluid {
  width: 100%;
  @include make-container;
  box-sizing: border-box;
}

@each $key, $value in $container-widths {
  .container {
    @include media-up($key) {
      max-width: $value;
    }
  }
  .container-#{$key} {
    max-width: $value;
    width: 100%;
    @include make-container(0);
  }
}

.bg-primary {
  background-color: $bg-primary;
}

.bg-secondary {
  background-color: $bg-secondary;
}

.bg-app-bg {
  background: url('./images/bg.png') center / cover no-repeat, transparent;
}

.position-relative {
  position: relative;
}

.margin-auto {
  margin: 0 auto !important;
}

.p-0 {
  padding: 0 !important;
}

.content-wrapper {
  box-sizing: border-box;
  padding: 100px 100px 50px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  color: $color-primary;
  height: inherit;
  .content, .preview-wrapper {
    width: 50%;
  }
  @media screen and (max-width: 998px) {
    .content h1 {
      font-size: 50px;
      line-height: 1.2;
    }
  }
  @media screen and (max-width: 768.98px) {
    flex-direction: column;
    padding: 0;
    .content, .preview-wrapper {
      width: 100%;
    }
    .content h1 {
      font-size: 42px;
      line-height: 1.2;
    }
  }
}

.preview-wrapper img {
  width: 100%;
  height: auto;
}

.preview-wrapper {
  @include media-up('xl') {
    transform: translateY(60px);
  }
  @include media-up('xxl') {
    transform: translateY(60px);
  }
}

.content h1 {
  font-size: 64px;
}

.flex-row-between {
  @include flex($justify: space-between);
}

.flex-center {
  @include flex($justify: center);
}

.svg-wrapper {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: -0.5%;
}

.text-small {
  font-size: 12px;
  font-weight: 200;
}

.divider {
  position: absolute;
  width: 2px;
  height: 100%;
  left: calc(100vw / 2 - 1px);
  top: 0;
  background-color: aqua;
  z-index: 2000;
}
