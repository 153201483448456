@font-face {
	font-family: 'Courgette';
	src: local('Cougrette'), local('Cougrette'),
		url('Courgette/Courgette-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Overlock';
	src: local('Overlock'), local('Overlock'),
		url('Overlock/Overlock-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Overlock-Bold';
	src: local('Overlock-Black'), local('Overlock-Black'),
		url('Overlock/Overlock-Bold.ttf') format('truetype');
	font-weight: 900;
	font-style: bold;
}