@import "../../scss/variables";
@import "../../scss/mixins";

.contact-us {
  padding: 100px 0;
  h2 {
    text-align: center;
    font-size: 52px;
  }
}

.form {
  input, textarea {
    width: 100%;
    font-size: 18px;
    padding: 6px;
    margin-bottom: 20px;
    border: 1px solid $contact-input-border-color;
    border-radius: 6px;
    box-shadow: none;
    font-family: 'Overlock';

    &:focus-visible {
      outline: none;
      border-color: darken($color: $contact-input-border-color, $amount: 20%);
      box-shadow: none;
    }

    &.warning {
      border-color: red;
    }
    &.warning + span {
      font-size: 14px;
      position: absolute;
      left: 0;
      bottom: -5px;
      display: inline-block;
      color: #FF0000;
    }
  }
  label {
    font-size: 20px;
    font-weight: 600;
  }
  button[type="submit"] {
    font-size: 18px;
    padding: 6px 2rem;
    background-color: #EEEEEE;
    border: 1px solid $contact-input-border-color;
    border-radius: 6px;
    font-family: 'Overlock';
    &:not(:disabled) {
      cursor: pointer;
    }
    @media screen and (max-width: 576px){
      width: 100%;
    }
  }
}

.required {
  color: #FF0000;
  margin-left: 5px;
}

$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .5rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            rgba(#fff, .85) !default;
$toast-border-width:                1px !default;
$toast-border-color:                rgba(#000, .1) !default;
$toast-box-shadow:                  0 .5rem 1rem rgba(#000, .15) !default;
$toast-spacing:                     15px !default;

// $toast-header-color:                #6c757d !default;
$toast-header-color:                $color-primary;
$toast-header-background-color:     rgba($bg-primary, .85) !default;
$toast-header-border-color:         rgba(#000, .05) !default;

.toast {
  width: $toast-max-width;
  max-width: 100%;
  font-size: $toast-font-size;
  color: $toast-color;
  pointer-events: auto;
  background-color: $toast-background-color;
  background-clip: padding-box;
  border: $toast-border-width solid $toast-border-color;
  box-shadow: $toast-box-shadow;
  border-radius: 6px;
  transition: opacity 0.5 ease-in-out;
  &.showing {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  // &:not(.show) {
  //   display: none;
  // }
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: $toast-spacing;
  }
}

.toast-header {
  display: flex;
  align-items: center;
  padding: $toast-padding-y $toast-padding-x;
  color: $toast-header-color;
  background-color: $toast-header-background-color;
  background-clip: padding-box;
  border-bottom: $toast-border-width solid $toast-header-border-color;
  border-radius: 6px 6px 0 0;

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 10px;
  }

  .btn-close {
    margin-right: $toast-padding-x * -.5;
    margin-left: $toast-padding-x;
  }
}

.toast-body {
  padding: $toast-padding-x; // apply to both vertical and horizontal
  word-wrap: break-word;
}

$btn-close-width:            1.2em !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-color:            #000 !default;
$btn-close-bg:               url("../../images/svg/close.svg");
$btn-close-focus-shadow:     0 .5rem 1rem rgba(#000, .15) !default;
$btn-close-opacity:          1 !default;
$btn-close-hover-opacity:    .75 !default;
$btn-close-focus-opacity:    1 !default;
$btn-close-disabled-opacity: .25 !default;

.btn-close {
  box-sizing: content-box;
  width: $btn-close-width;
  height: $btn-close-height;
  padding: 0.25em;
  color: $btn-close-color;
  background: transparent $btn-close-bg center center / $btn-close-width auto no-repeat;
  border: 0;
  opacity: $btn-close-opacity;
  cursor: pointer;

  &:hover {
    color: $btn-close-color;
    text-decoration: none;
    opacity: $btn-close-hover-opacity;
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-close-focus-shadow;
    opacity: $btn-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $btn-close-disabled-opacity;
  }
}

.toast-danger {
  background-color: rgba($bg-danger, 0.5);
  color: $bg-danger;
}

.toast-c-container {
  position: fixed;
  right: 15px;
  bottom: 150px;
}
