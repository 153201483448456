@import "../../../scss/mixins";

.svg-anim-wrapper {
  position: relative;
  width: 100%;
  @include media-up('xs') {
    height: 200px;
    transform: translateY(50px);
  }
  @include media-up('md') {
    height: 200px;
    transform: translateY(50px);
  }
  @include media-up('lg') {
    height: 200px;
    transform: translateY(3.6vw);
  }
  @include media-up('xl') {
    height: 16vw;
    transform: translateY(4.2vw);
  }
  @include media-up('xxl') {
    height: 16vw;
    transform: translateY(6vw);
  }
}

.svg-wave-wrapper {
  position: absolute;
  width: 6400px;
  height: 12vw;
  @include media-up('lg') {
    height: 200px;
  }
  @include media-up('md') {
    height: 200px;
  }
  @include media-up('xs') {
    height: 200px;
  }
}

.wave {
  z-index: 1003;
  background: url('../../../images/svg/basic-wave.svg') repeat-x, transparent;
  animation: wave var(--animation-time) cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave-2 {
  z-index: 1002;
  background: url('../../../images/svg/basic-wave-2.svg') repeat-x, transparent;
  animation: wave var(--animation-time) cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell 10s ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
}

.wave-3 {
  z-index: 1001;
  background: url('../../../images/svg/basic-wave-3.svg') repeat-x, transparent;
  animation: wave var(--animation-time) cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell-2 10s ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -3200px;
  }
}

@keyframes swell {
  0%,
	100% {
    transform: translate3d(2px, -5px, 0);
  }
  50% {
    transform: translate3d(40px, -15px, 0);
  }
}

@keyframes swell-2 {
  0%,
	100% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -35px, 0);
  }
}

.endWave{
	display: none;
}