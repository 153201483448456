@import "../../scss/variables";
@import "../../scss/mixins";

.App-Footer {
  background-color: $bg-footer;
  @media screen and (max-width: 767.98px) {
    h4 {
      margin-bottom: 10px;
    }
    .block-column p {
      margin: 10px 0 !important;
    }
    .flex-center {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .text-small {
      margin-top: 30px;
    }
  }
}

.App-Footer a {
  text-decoration: none;
  color: black;
  font-size: 16px;
}

.centered-flex {
  flex-grow: 1;
  @include flex($display: inline-flex ,$justify: center);
  .block-column {
    padding-right: 3.4vw;
    @media screen and (min-width: #{map-get($breakpoints, xl)}) {
      padding-right: 4vw;
    }
    @media screen  and (max-width: #{map-get($breakpoints, lg)}) {
      padding-right: 5.5vw;
    }
  }
}
