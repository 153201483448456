$bg-primary: rgb(128, 64, 225);
$bg-secondary: #F8F9FA;
$bg-footer: #F8F9FA;
$bg-danger: #ea3939;

$color-primary: #FFFFFF;

$contact-input-border-color: #ccc;

$container-padding-x: 15px;
$container-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);